<template>
  <div class="wrap">
   <Loading v-show="isLoading"/>
   <div v-show="!isLoading">
    <div v-show='!isEmpty' class="wrap1">
      <van-checkbox-group v-model="resultList" @change="handdleChange">
        <van-col class="contractCol" span="22" offset="1" v-for="(item,index) in list" :key="index" style="margin-top:10px;border-radius:5px;">
          <van-cell :center="true">
            <template #title>
              <van-checkbox :name="item" ref="checkboxes" class="checkboxes" icon-size="15px" checked-color="#E50012">
                <span class="custom-title">申请编号：{{item.tradeCode}}</span>
                <span style="float:right;color: #3662EC;font-size:13px;">{{ item.status }}</span>
              </van-checkbox>
            </template>
            <template #label>
              <div @click="haddleCheck(index)">
                <div class="cell-label">
                  <span class="custom-label">合同编码：</span>
                  <span class="custom-value">{{item.contractCode}}</span>
                </div>
                <div class="cell-label">
                  <span class="custom-label">产品名称：</span>
                  <span class="custom-value">{{item.productName}}</span>
                </div>
                <div class="cell-label">
                  <span class="custom-label">客户名称：</span>
                  <span class="custom-value">{{item.customerName}}</span>
                </div>
                <div class="cell-label">
                  <span class="custom-label">缴纳保证金期数：</span>
                  <span class="custom-value">{{item.rentNum}}</span>
                </div>
                <div style="margin-top: 20px;">
                  <span style="font-size: 12px;color:#333;">前置保证金：</span>
                  <span style="color:#E50012;font-size:15px;">{{ item.totalRent ||'--' }}元</span>
                  <span style="float:right;font-size: 12px;color: #666;" @click.stop="handdleShowPopup(index)">车辆详情<van-icon name="arrow" style="font-size: 14px;position: relative;top: 3px;margin-left: 4px;" /></span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-col>
      </van-checkbox-group>
      <van-popup
        v-model="showPopup"
        closeable
        close-icon-position="top-left"
        position="bottom"
        round
        :style="{ minHeight: '50%',maxHeight: '80%',overflow:'hidden' }"
      >
        <div class="tipTitile">车辆详情</div>
        <div class="popup-wrap">
          <div class="popupCont" v-for="(item,index) in popupData" :key="index">
            <span class="pop-span">{{ item.brandName }}</span>
            <span class="pop-span">{{ item.carKind }}</span>
            <span class="pop-span">{{ item.carStyle }}</span>
            <span class="pop-span">{{ item.carVin }}</span>
          </div>
        </div>
      </van-popup>
      <div class="btnBottom">
        <van-col span="20" offset="2">
          <van-button v-if="isReadonly" disabled round block color="#ddd" style="opacity:1">
          确定选择
          </van-button>
          <van-button v-if="!isReadonly" round block type="danger" @click="handdleSubmit">
          确定选择
          </van-button>
        </van-col>
      </div>
    </div>
    <van-empty v-show='isEmpty'
      class="custom-image"
      :image="emptyImg"
      description="暂无数据"
    />
    </div>
  </div>
    
</template>
<script>
  import {getOpenId,getCorpPayContractList,confirmCorpPayContractList} from '@/server'
  import { Toast,Dialog } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,getBindStatus,hidewxOption} from '@/lib/utils';
  export default {
    name: 'publicAdvanceDeposit',
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      accessToken:this.$store.state.accessToken,
      finished: false,
      isLoading:true,
      isEmpty:false,
      emptyImg:require('@/assets/img/empty.png'),
      isReadonly:true,
      showPopup:false,
      popupData:{},
      resultList: [],
      checked:false,
      list:[],
    };
  },
  mounted(){
  },
  created(){
    //隐藏分享
    hidewxOption()
    // if(sessionStorage.getItem("source")=='1'){////0 正常 ，1 从修正付款信息-重签合同过来的 1 从付款信息
    //   sessionStorage.setItem("origin",'0')//0 重选后添加数据 1原始数据
    // }else{
    //   sessionStorage.setItem("origin",'1')
    // }
    this.getDetail()
    // getBindStatus(this.code,this.openId,this.cardId,this.accessToken,"/publicAccount/advanceDeposit",this.getDetail())
  },
  methods: {
    getDetail(){
      const params = {
        certId: this.cardId,
        type: "01",
        payInfoId:sessionStorage.getItem("payInfoId") || null,
        customers:sessionStorage.getItem("customers").slice(0,-1)
      }
      getCorpPayContractList({data:params}).then(res=>{
        const {data,status}=res
        if(status == '200'){
          this.isLoading = false;
          if(data.length==0){
            this.isEmpty = true;
          }else{
            this.isEmpty = false;
            this.list = data;
          }
        }else{
          this.isLoading = false;
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    },
    handdleShowPopup(index){
      this.popupData = this.list[index].carList;
      this.showPopup = true;
    },
    handdleChange(value){
      if(value.length==0){
        this.isReadonly = true;
      }else{
        this.isReadonly = false;
      }
    },
    haddleCheck(index){
      this.$refs.checkboxes[index].toggle()
    },
    handdleSubmit(){
      const params = {
        payerId:this.openId,
        corpList:this.resultList,
        certId:sessionStorage.getItem("loginCard"),
        payInfoId:sessionStorage.getItem("payInfoId") || null,
        source:sessionStorage.getItem("source") || '0'//0 正常 ，1 从付款信息
      }
      confirmCorpPayContractList({data:params}).then(res=>{
        // this.$store.commit('updatedIsLoading', false);
        if(res.status == '200'){
          // sessionStorage.setItem("payInfoId",res.data);
          if(sessionStorage.getItem("source")=='1'){
            sessionStorage.setItem("origin",'0')//0 重选后添加数据 1原始数据
            this.$router.go(-1);
          }else{
            sessionStorage.setItem("origin",'1')//0 重选后添加数据 1原始数据
            this.$router.push({path: '/enterprise/enterpriseAdvanceDeposit/payment',query:{id:res.data}});
          }
        }else{
          Toast.fail({
            message: res.subMsg || res.msg,
            duration:'3000'
          });
        }
      })
    }
  },
};
</script>
<style>
  .checkboxes .van-checkbox__label{
    width: 100% !important;
  }
  .popupCont .van-cell{
    font-size: 15px !important;
  }
  .popupCont .van-cell__value{
    flex: 2 !important;
  }
</style>>
<style scoped lang="less">
  .wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8;
    .wrap1{
      min-height: 100vh;
      // overflow: hidden;
      // padding-bottom: 120px;
      .contractCol:last-child{
        padding-bottom: 120px;
      }
    }
    .tabs{
      padding: 15px 10px;
      height: 35px;
      line-height: 35px;
      // width: 100%;
      background-color: #fff;
      .tab-wrap{
        background-color: #ECECEC;
        display: flex;
        border-radius: 2px;
        .tab{
          flex: 1;
          display: inline-block;
          text-align: center;
          margin: 5px;
          color: #888;
          cursor: pointer;
        }
        .tabActive{
          // margin: 5px;
          background-color: #fff;
          border-radius: 3px;
          color: #333;
        }
      }
      
    }
    .popup-wrap{
      min-height:50vh;
      max-height:80vh;
      overflow: auto;
      padding-bottom: 20px;
      .popupCont:last-child{
        padding-bottom: 120px;
      }
    }
    .popupCont{
      padding: 15px 0px 7px 0px;
      margin: 0 15px;
      border-bottom: 1px solid #eee;
      font-size: 15px;
      color: #666;
      .pop-span{
        display: block;
        margin-bottom: 8px;
      }
    }
  }
  .btnBottom{
    position: fixed;
    width: 100%;
    background-color: #fff;
    bottom: 0px;
    padding-bottom: 15px;
    padding-top:10px;
  }
  .tipTitile{
    color: #1A1A1A;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #eee;
    height: 60px;
    line-height: 60px;
    /* margin-top: 10px; */
  }
  .cell-label{
    margin-top: 10px; 
    padding-left: 8px;
    width: 6rem;
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
  }
  .custom-title{
    font-size:12px;
    color:#666;
  }
  .custom-label{
    color:#333333;
    font-size: 13px;
    font-weight: bold;
  }
  .custom-value{
    font-size: 13px;
    color:#333333;
  }
</style>